import { Guid } from "guid-typescript";
import { VisaEventEnum } from "@/utils/Enums"

export default class Validation {
    public id:string;
    public entrepriseName:string;
    public entrepriseId:string;
    public representativeName:string;
    public representativeId:string;
    public createdDate:string;
    public event: any;

    constructor(){
        this.id = Guid.create().toString();
        this.entrepriseId = Guid.createEmpty().toString();
        this.representativeId = Guid.createEmpty().toString();
        this.entrepriseName = '';
        this.representativeName = '';
        this.createdDate = new Date().toJSON();

        this.event = {
            event: {
                key: VisaEventEnum.Signature.toString()
            }
        }
    }
}