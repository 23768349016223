export default class Service {

    public serviceNumber:string;
    public serviceName:string;
    public serviceType:string;
    constructor(){
        this.serviceNumber = '';
        this.serviceName = '';
        this.serviceType = '';
    }
}