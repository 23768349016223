import apiProvider from "@/utils/api";
import axios, { AxiosPromise } from "axios";
import { toUrl } from "@/utils/helpers";
import Validation from "@/models/Visa";
const rootPath = "Visas";

class VisaServices {
  public getTableUrl(idBop, lang): string {
    return `${apiProvider.getUrl()}${rootPath}${toUrl({ idBop, lang })}`;
  }
  public saveAllVisas(visas: Array<Validation>, bopId: string, lang: string): AxiosPromise {
    return apiProvider.post(`${rootPath}${toUrl({ bopId, lang })}`, visas);
  }
  public deleteVisa(visaId:string): AxiosPromise {
    return apiProvider.delete(`${rootPath}/${visaId}`);
  }
}

export default new VisaServices();
