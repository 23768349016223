

















































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Validation from "@/models/Validation";
import SapApiError from "@/models/SapApiError";
import Services from "@/models/Services";
import SapApiService from "@/services/SapApiService";
import EntrepriseService from "@/services/EntreprisesServices";
import {
  ImputationTypeEnum,
  BopModelEnum,
  BopStatus,
  UserRoleEnum,
  UserProfilEnum,
  VisaEventEnum,
} from "@/utils/Enums";
import Bop from "@/models/Bop";
import Visa from "@/models/Visa";
import VisaService from "@/services/VisaServices";
import BopStatusService from "@/services/BopStatusService";
import router from "@/router";
import BopService from "@/services/BopService";
import MinimumFacturableService from "@/services/MinimumFacturableService";
import MinimumFacturable from "@/models/MinimumFacturable";
import { Guid } from "guid-typescript";
import Constants from "@/utils/Constants";
import { roundNumber } from "@/utils/helpers";
import { AxiosError } from "axios";
import bopService, { GeneratePdfDto } from "@/services/BopService";

@Component({
  components: {
    Table: () => import("@/components/Shared/Table/Table.vue"),
    VisaModal: () => import("./VisaModal.vue"),
  },
})
export default class FacturationStep extends Vue {
  @Prop({ default: false })
  private isReadOnly!: boolean;
  @Prop({ default: true })
  private showVisa!: boolean;
  @Prop({ default: false })
  private isValidation!: boolean;

  private readonly visaEventEnum = VisaEventEnum;

  private tableKey: string = Guid.create().toString();
  private validation: Validation = new Validation();
  private imputationTypes: Array<Object> = [];
  private allServices: Array<Services> = [];
  private modifiedVisas: Array<Visa> = [];
  private totalPointsSaved: number = 0;
  private visaColumns: Array<Object> = [];
  private showVisaModal: Boolean = false;
  private visaUrl: string = "";
  private canShowLoader: boolean = false;
  private get isEdit(): boolean {
    return (
      this.$route.query.isEdit !== undefined &&
      this.$route.query.isEdit === "true"
    );
  }
  private get sapOtNumber(): string {
    return this.$route.query.otNumber?.toString() ?? "";
  }

  private saveDisabled: boolean = false;
  private minimumFacturable: number = 0;
  private get isEnabled(): boolean {
    return (
      this.bop.bopStatusKey !== BopStatus.Archived &&
      this.bop.bopStatusKey !== BopStatus.Finished
    );
  }
  get totalSuppPoints(): number {
    return this.bop.totalOperationSuppHeurePoints;
  }
  /**
   * Check wether the services are valid or not
   */
  get areValidServices() {
    return (
      (this.totalSuppPoints === 0 ||
        this.validation.sapSupplementServiceNumber.length) &&
      //epi validation
      (this.bop.totalOperationSuppEpiPoints === 0 ||
        this.validation.sapEPIServiceNumber.length) &&
      //general service validation
      this.validation.sapPrestationServiceNumber &&
      this.validation.sapPrestationServiceNumber.length
    );
  }
  private get bopShort(): String {
    return `${
      this.bop.siteName
    } - ${this.bop.bopModelName.toString().toLowerCase()} ${
      this.bop.bopNumber && this.bop.bopNumber.toString().length ? " # " : ""
    }${this.bop.bopNumber}`;
  }

  private get isTuyauterieMetalique(): boolean {
    if (this.bop.bopModelKey === BopModelEnum[BopModelEnum.TuyauterieMetalique])
      return true;
    else return false;
  }

  private get isEditable(): boolean {
    return (
      (!this.bop.isCreatedBySap &&
        this.bop.bopStatusKey === BopStatus.AcceptLunchWork) ||
      (this.bop.bopStatusKey === BopStatus.Finished &&
        this.isEdit &&
        !this.isValidation) ||
      !this.isReadOnly
    );
  }
  private get isSendBopEnabled(): boolean {
    return this.bop.bopStatusKey === BopStatus.AcceptLunchWork;
  }

  private get isValidTotalPoint(): boolean {
    if (Number(this.bop.totalPoints) <= Number(this.totalPointsSaved)) {
      return true;
    }
    return false;
  }

  private get isEchafaudage(): boolean {
    if (this.bop.bopModelKey === BopModelEnum[BopModelEnum.Echafaudage])
      return true;
    else return false;
  }

  private get bopStatus() {
    return this.$store.state.bopStatuses
      ? this.$store.state.bopStatuses[this.bop.bopStatusKey]
      : "";
  }

  private get validationDisabled(): boolean {
    if (!this.areValidServices) return true;
    return false;
  }

  private get sendDisabled(): boolean {
    if (!this.areValidServices) return true;
    if (!this.isSendBopEnabled && !this.isEdit) return true;
    if (this.validation.imputationType === "") return true;
    if (
      this.validation.purchaseOrder.length !== 10 ||
      this.validation.itemNumber.length < 0 ||
      this.validation.itemNumber.length > 5
    )
      return true;
    if (
      this.validation.imputationType === "K" &&
      this.validation.costCenter.length === 0
    )
      return true;
    if (
      this.validation.imputationType === "F" &&
      this.validation.order.length === 0
    )
      return true;
    if (
      this.validation.imputationType === "Z" &&
      (this.validation.costCenter.length === 0 ||
        this.validation.WBSelement.length === 0)
    )
      return true;
    if (this.isEdit && !this.isValidTotalPoint) return true;
    return false;
  }

  private get addServices(): Array<Services> {
    const array = this.allServices.filter((x) => x.serviceType === "add");
    array.unshift(new Services());
    return array;
  }

  private get epiServices(): Array<Services> {
    const array = this.allServices.filter((x) => x.serviceType === "epi");
    array.unshift(new Services());
    return array;
  }

  private get prestaServices(): Array<Services> {
    const array = this.allServices.filter((x) => x.serviceType === "presta");
    array.unshift(new Services());
    return array;
  }

  private get imputationSelected(): boolean {
    return this.validation.imputationType !== "";
  }

  private get showCostCenter(): boolean {
    return this.validation.imputationType !== "F";
  }

  private get showOrder(): boolean {
    return this.validation.imputationType === "F";
  }

  private get showWBSelement(): boolean {
    return this.validation.imputationType === "Z";
  }

  private get bop(): Bop {
    return this.$store.state.bop;
  }
  // private set bop(value: Bop) {
  //   this.$store.commit("changeBop", value);
  // }
  private get bopTotalOperationPrincipalDiversePoints() {
    return roundNumber(
      this.bop.totalOperationDiversePoints +
        this.bop.totalOperationPrincipalPoints
    );
  }
  private get totalOperationSuppPoints(): number {
    const total =
      (this.bop.totalSuppHauteurPoints ?? 0) +
      this.bop.totalOperationSuppHeurePoints +
      this.bop.totalOperationSuppEpiPoints;
    return roundNumber(total);
  }
  private get totalPoints() {
    let total = this.bop.totalPoints;
    if (this.isEdit || !Bop.readOnlyStatuses.includes(this.bop.bopStatusKey)) {
      const totalOpprincipaleDiverse =
        this.bopTotalOperationPrincipalDiversePoints > this.minimumFacturable
          ? this.bopTotalOperationPrincipalDiversePoints
          : this.minimumFacturable;
      total = totalOpprincipaleDiverse + this.totalOperationSuppPoints;
      this.bop.totalPoints = total;
    }
    return roundNumber(total);
  }
  private get isVisaVisible(): boolean {
    return (
      this.showVisa &&
      (<Array<string>>[
        BopStatus.AcceptLunchWork,
        BopStatus.Finished,
        BopStatus.Archived,
      ]).includes(this.bop.bopStatusKey)
    );
  }
  private get isCreatedBySapAndEditable() {
    return (
      this.bop.isCreatedBySap &&
      (<Array<string>>[
        BopStatus.Initial,
        BopStatus.Simulation,
        BopStatus.WaitingForValidation,
      ]).includes(this.bop.bopStatusKey)
    );
  }

  private onTableLoaded() {
    this.isFebValidate = (<any>this.$refs.table).initialRows.some(
      (validation) =>
        validation.entrepriseId != Guid.EMPTY &&
        validation.event.event.key === VisaEventEnum.Signature.toString()
    );
  }

  private isFebValidate: boolean = false;

  private get isUserAbleSign() {
    // this.$store.getters.isAllowed([UserRoleEnum.orderGiver]) &&
    return (
      this.$store.getters.user.profil.profilKey == UserProfilEnum.arkemaUser &&
      !this.isFebValidate
    );
  }

  /**
   * #####
   * Methods
   * #####
   */
  private refreshTableKey() {
    if (!this.isEnabled) return;
    this.tableKey = Guid.create().toString();
  }
  public add(newVisa: Visa = new Visa()): void {
    (<any>this.$refs.table).updatedRows.unshift(newVisa);
    (<any>this.$refs.table).modify(newVisa);
    // console.log(this.bop.visas);
  }

  private async remove(props) {
    // console.log("Im beign removed :O");
    // console.log(props);
    const visa: Visa = props.rowData;
    const modalResponse = await this.$bvModal.msgBoxConfirm(
      this.$t("bopConfirmDelteVisa.message").toString(),
      {
        title: this.$t("bopConfirmDelteVisa.title").toString(),
        size: "lg",
        buttonSize: "lg",
        okVariant: "primary",
        cancelVariant: "secondary",
        okTitle: this.$t("bopConfirmDelteVisa.yes").toString(),
        cancelTitle: this.$t("bopConfirmDelteVisa.no").toString(),
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
        dialogClass: "modal-app",
      }
    );
    if (modalResponse) {
      try {
        const result = await VisaService.deleteVisa(visa.id);
        if (result) {
          const index = props.rowIndex;
          // if (index > -1)
          (<any>this.$refs.table).updatedRows.splice(index, 1);
          const modifiedDataIndex = this.modifiedVisas.findIndex(
            (el) => el.id === visa.id
          );
          this.modifiedVisas.splice(modifiedDataIndex, 1);
          this.notify(
            this.$t("deleteVisa.successTitle").toString(),
            this.$t("deleteVisa.successMessage").toString(),
            "success"
          );
        } else {
          this.notify(
            this.$t("deleteVisa.errorTitle").toString(),
            this.$t("deleteVisa.errorMessage").toString(),
            "error"
          );
        }
      } catch (error) {
        if (error?.response?.data?.detail) {
          this.notify(
            this.$t(
              `saveVisa.${error?.response?.data?.detail}Title`
            ).toString(),
            this.$t(
              `saveVisa.${error?.response?.data?.detail}Message`
            ).toString(),
            "error"
          );
        } else {
          this.notify(
            this.$t("saveVisa.errorTitle").toString(),
            this.$t("saveVisa.errorMessage").toString(),
            "error"
          );
        }
      }
    }
  }

  private validateVisasFields(visas: Array<Visa>) {
    for (let i = 0; i < visas.length; i++) {
      const visa: Visa = this.modifiedVisas[i];
      if (!visa.entrepriseName.length || !visa.representativeName.length) {
        return false;
      }
    }
    return true;
  }

  public async sign(visaEvent: VisaEventEnum) {
    if (!this.isEnabled) return;
    if (!this.validateVisasFields(this.modifiedVisas)) {
      this.notify(
        this.$t("saveVisa.errorTitle").toString(),
        this.$t("saveVisa.emptyFieldsErrorMessage").toString(),
        "error"
      );
      return;
    }
    // console.log(this.$store.getters.user.lastName);
    const newVisa = new Visa();
    newVisa.entrepriseName = (
      await EntrepriseService.getByIdAndLanguage(
        // Error : undefined user id
        this.$store.getters.user.entrepriseId,
        "fr"
      )
    )?.data?.name;
    newVisa.representativeName = `${this.$store.getters.user.firstName} ${this.$store.getters.user.lastName}`;
    newVisa.entrepriseId = this.$store.getters.user.entrepriseId;
    newVisa.representativeId = this.$store.getters.user.id;
    newVisa.event.event.key = visaEvent;
    this.add(newVisa);

    await this.saveVisas(visaEvent);
  }

  private async saveVisas(visaEvent) {
    this.canShowLoader = true;
    try {
      const result = await VisaService.saveAllVisas(
        this.modifiedVisas,
        this.bop.id,
        this.$i18n.locale
      );

      if (result) {
        if (visaEvent === VisaEventEnum.Signature) {
          this.notify(
            this.$t("saveVisa.successTitle").toString(),
            this.$t("saveVisa.successMessage").toString(),
            "success"
          );
        } else if (visaEvent === VisaEventEnum.ValidationRequest) {
          this.notify(
            this.$t("saveValidationVisa.successTitle").toString(),
            this.$t("saveValidationVisa.successMessage").toString(),
            "success"
          );
        }

        (<any>this.$refs.table).refreshAPI();
      }
    } catch (error) {
      if (error?.response?.data?.detail) {
        this.notify(
          this.$t(`saveVisa.${error?.response?.data?.detail}Title`).toString(),
          this.$t(
            `saveVisa.${error?.response?.data?.detail}Message`
          ).toString(),
          "error"
        );
      } else {
        this.notify(
          this.$t("saveVisa.errorTitle").toString(),
          this.$t("saveVisa.errorMessage").toString(),
          "error"
        );
      }
      this.refreshTableKey();
    } finally {
      this.canShowLoader = false;
    }
    this.modifiedVisas = [];
  }

  private notify(title: string, text: string, type: string) {
    const isError = type === "error";
    let notifObject: any = {
      group: isError ? "globalError" : "global",
      type,
      title,
      text,
    };
    if (type === "error") {
      notifObject.duration = Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS;
    }
    this.$notify(notifObject);
  }

  public async sendBop() {
    try {
      this.canShowLoader = true;
      if (this.saveDisabled || this.isCreatedBySapAndEditable) return;
      const result = this.isEdit ? await this.save() : true;
      if (!result) {
        this.$notify({
          group: "globalError",
          type: "error",
          title: this.$t(`bop.saveErrorTitle`).toString(),
          text: this.$t(`bop.saveErrorMessage`).toString(),
          duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
        });
        return;
      }
      this.saveDisabled = true;
      this.validation.language = this.$i18n.locale;
      const response = await SapApiService.sendBop(
        this.bop.id,
        this.validation
      );
      if (response.status === 200) {
        const generatePdfDto: GeneratePdfDto = {
          bopLink: this.validation.bopLink,
          languageCode: this.$i18n.locale,
        };
        setTimeout(() => {
          bopService.generatePdf(generatePdfDto, this.bop.id);
        });
        this.$notify({
          group: "global",
          type: "success",
          title: this.$t("bopSetup.facturation.send.success.title").toString(),
          text: this.$t("bopSetup.facturation.send.success.text").toString(),
        });
        await router.push({
          name: "Bop",
        });
      }
    } catch (err) {
      this.saveDisabled = false;
      const response = err.response;
      let text = err.response.data.error;
      if (response.status === 400) {
        if (response.data.error === "noVisaByArkema") {
          text = this.$t("bopSetup.cantSendBopNoVisaByArkema").toString();
        }
      }
      this.$notify({
        group: "globalError",
        type: "error",
        title: this.$t("bopSetup.error.sendBopToSap.title").toString(),
        text,
        duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
      });
    } finally {
      this.canShowLoader = false;
    }
  }

  private async getServices() {
    try {
      let response = await SapApiService.getServices(
        this.validation.purchaseOrder,
        this.validation.itemNumber,
        this.$i18n.locale
      );
      this.allServices = response.data;
    } catch (err) {
      this.$notify({
        group: "globalError",
        type: "error",
        title: this.$t("bopSetup.error.noServices.title").toString(),
        text: err.response.data.error,
        duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
      });
    }
  }
  public async save() {
    if (this.saveDisabled) return;
    this.saveDisabled = true;
    try {
      // console.log(this.bop);
      // console.log(this.$parent);
      if (!(<any>this.$parent.$parent).validateBop(this.bop)) {
        return;
      }
      const response = await BopService.save(this.bop, this.isEdit);
      if (response.status === 200) {
        this.saveDisabled = false;
        this.$store.commit("changeHasBeenModified", false);
        return true;
      }
    } catch (err) {
      this.saveDisabled = false;
      return false;
    }
  }
  private loadVisa() {
    this.visaUrl = VisaService.getTableUrl(this.bop.id, this.$i18n.locale);

    this.visaColumns = [
      {
        name: "editable",
        title: this.$t("bopSetup.facturation.visa.entrepriseName").toString(),
        property: "entrepriseName",
        isNotEditable: true,
      },
      {
        name: "editable",
        title: this.$t(
          "bopSetup.facturation.visa.representativeName"
        ).toString(),
        property: "representativeName",
        isNotEditable: true,
      },
      {
        name: "editable",
        title: this.$t("bopSetup.facturation.visa.createdDate"),
        property: "createdDate",
        isDate: true,
        isNotEditable: true,
      },
      {
        name: "editable",
        title: this.$t("bopSetup.facturation.visa.event"),
        property: "event.translation.name",
        isNotEditable: true,
      },
      {
        name: "actions",
        title: "",
        removeButton: this.isEnabled && !this.isUserAbleSign,
        editButton: false,
        duplicateButton: false,
      },
    ];
  }

  private showInvoice() {
    return !(this.$store.getters.userRoleKey == "orderGiver");
  }
  /**
   * #####
   * Hooks
   * #####
   */
  private created() {
    if (this.isVisaVisible) {
      this.loadVisa();
    }
    const validation = new Validation();
    validation.language = this.$i18n.locale;
    validation.totalPrincipalDiversPoint =
      this.bop.totalOperationPrincipalPoints +
      this.bop.totalOperationDiversePoints;
    validation.totalAddPoints = this.bop.totalOperationSuppHeurePoints;
    validation.totalEpiPoints = this.bop.totalOperationSuppEpiPoints;
    validation.sapPrestationServiceNumber =
      this.bop.sapPrestationServiceNumber ?? "";
    validation.itemNumber = this.bop.itemNumber ?? "";
    validation.WBSelement = this.bop.wbsElement ?? "";
    validation.costCenter = this.bop.costCenter ?? "";
    validation.order = this.bop.otNumber ?? "";
    validation.imputationType = this.bop.imputationType ?? "";
    validation.materialCost = this.bop.materialCost ?? 0;
    validation.purchaseOrder = this.bop.purchaseOrder ?? "";
    validation.rentingCost = this.bop.rentingCost ?? 0;
    validation.sapEPIServiceNumber = this.bop.sapEPIServiceNumber ?? "";
    validation.sapSupplementServiceNumber =
      this.bop.sapSupplementServiceNumber ?? "";
    validation.studyCost = this.bop.studyCost ?? 0;
    validation.subcontractingCost = this.bop.subcontractingCost ?? 0;
    validation.bopLink = location.href.replace(
      this.bop.id,
      (this.bop.bopNumber ?? this.bop.id).toString()
    );
    validation.bopStatus = this.bopStatus;
    validation.itemDa = this.bop.itemDA;
    validation.daId = this.bop.daNumber;

    MinimumFacturableService.getByEntrepriseBopModelDomainApplication(
      this.bop.bopModelId ?? "",
      this.bop.entrepriseId ?? "",
      this.bop.typeTravaux ?? ""
    ).then((response) => {
      const minimFacturable: MinimumFacturable = response.data;
      this.minimumFacturable = minimFacturable.minimumPoints;
    });
    BopService.getCurrrentTotalPoint(this.bop.id).then((result) => {
      this.totalPointsSaved = result.data;
    });
    // if the bop is created by sap
    if (this.bop.isCreatedBySap) {
      const orderKey = "F";
      this.imputationTypes.push({
        text: this.$t(`bopSetup.facturation.${ImputationTypeEnum[orderKey]}`),
        value: orderKey,
      });
      validation.imputationType = orderKey;
    } else {
      const imputationTypes = Object.keys(ImputationTypeEnum);
      imputationTypes.forEach((key) =>
        this.imputationTypes.push({
          text: this.$t(`bopSetup.facturation.${ImputationTypeEnum[key]}`),
          value: key,
        })
      );
    }
    this.validation = validation;
  }
  /**
   * #####
   * Watchers
   * #####
   */

  @Watch("validation", { deep: true })
  onValidationChange(newValue: any) {
    this.$store.commit("changeValidation", this.validation);
    if (this.isCreatedBySapAndEditable) {
      console.log("Im validation and im changed :)");
      this.bop.sapEPIServiceNumber = this.validation.sapEPIServiceNumber;
      this.bop.sapPrestationServiceNumber = this.validation.sapPrestationServiceNumber;
      this.bop.sapSupplementServiceNumber = this.validation.sapSupplementServiceNumber;
      this.bop.imputationType = this.validation.imputationType;
      this.bop.purchaseOrder = this.validation.purchaseOrder;
      this.bop.otNumber = this.validation.order;
      this.bop.itemNumber = this.validation.itemNumber;
      this.bop.materialCost = this.validation.materialCost;
      this.bop.rentingCost = this.validation.rentingCost;
      this.bop.subcontractingCost = this.validation.subcontractingCost;
      this.bop.studyCost = this.validation.studyCost;
      this.bop.costCenter = this.validation.costCenter;
      this.bop.wbsElement = this.validation.WBSelement;
    }
  }

  @Watch("validation.itemNumber")
  async onItemNumberChange(newValue: any, oldvalue: any) {
    if (
      this.validation.itemNumber.length > 0 &&
      this.validation.itemNumber.length <= 5 &&
      this.validation.purchaseOrder.length === 10
    ) {
      if (this.allServices.length === 0) {
        await this.getServices();
      }
    } else {
      this.allServices = [];
      this.validation.sapEPIServiceNumber = "";
      this.validation.sapSupplementServiceNumber = "";
      this.validation.sapPrestationServiceNumber = "";
    }
  }

  @Watch("validation.purchaseOrder")
  async onPurchaseOrderChange(newValue: any, oldvalue: any) {
    if (
      this.validation.itemNumber.length > 0 &&
      this.validation.itemNumber.length <= 5 &&
      this.validation.purchaseOrder.length === 10
    ) {
      if (this.allServices.length === 0) {
        await this.getServices();
      }
    } else {
      this.allServices = [];
      this.validation.sapEPIServiceNumber = "";
      this.validation.sapSupplementServiceNumber = "";
      this.validation.sapPrestationServiceNumber = "";
    }
  }
}
